// Font Variables
$livvic: "Livvic", sans-serif;

.dashboard-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dashboard-photo {
  width: 55%;
  height: 500px;
  margin-top: 50px;
}

.icon-wrapper {
  margin-top: 20px;

  .travel-icon {
    color: #416165;
  }
}

.app-motto {
  margin: 15px auto;
  width: 50%;
  font-size: 24px;
  font-family: $livvic;
  color: #0b3948;
  text-align: center;
}

.mission-statement {
  margin: 30px auto;
  width: 50%;
  font-size: 21px;
  font-family: $livvic;
  color: #416165;
  text-align: center;
}