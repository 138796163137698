// Variables
$livvic: "Livvic", sans-serif;
$dancin: "Dancing Script", cursive;
$shadows: "Shadows Into Light", cursive;

$green: #306b34;
$offwhite: #eff1ed;
$crimson: #721817;
$lightblue: #416165;
$darkblue: #0b3948;

.ui.tiny.modal.transition.visible.active.modal-container {
  width: 500px;
}

.landing-wrapper {
  border: 1px solid $darkblue;
  border-radius: 3px;
  margin: auto;
  width: 100%;
  box-shadow: 3px 3px lightgray;
}

.modal-header {
  margin: 10px auto;
  margin-bottom: 10px;
  padding: 0;
  width: 100%;
  text-align: center;
  background-color: $lightblue;
}

.login-form {
  width: 60%;
  background-color: $offwhite;
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
}

.landing-input {
  border-radius: 3px;
  padding: 5px;
  width: 60%;
  font-family: $livvic;
}

.field-description {
  margin: 0 auto;
  width: 60%;
  font-size: 12px;
  font-family: $livvic;
  text-align: left;
  font-weight: bold;
}

.error {
  width: 60%;
  font-family: $livvic;
  font-size: 10px;
  color: red;
  padding: 0;
  margin: 0 auto;
  text-align: left;
}

.btn {
  width: 110px;
  padding: 10px 5px;
  border-radius: 4px;
  border: none;
  font-family: $livvic;
  font-weight: bold;
  color: $crimson;
  margin-top: 20px;
  margin-bottom: 10px;
  background: $crimson;
  text-align: center;

  &:hover {
    cursor: pointer;
  }
}

.register-link {
  margin: auto;
  width: 33%;
  font-size: 14px;
  padding-bottom: 20px;
  font-family: $livvic;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.button.ui.icon.right.labeled.button.btn.login-btn {
  font-family: $livvic;
  background-color: $crimson;
  color: $offwhite;
  margin: 20px 10px;

  &:hover {
    background-color: lighten($crimson, 10%);
  }
}

.button.ui.icon.right.labeled.button.btn.dashboard-btn {
  font-family: $livvic;
  background-color: $lightblue;
  color: $offwhite;
  margin: 20px 10px;

  &:hover {
    background-color: lighten($lightblue, 10%);
  }
}

